@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
================================================
    Import and apply Font.
================================================
*/

@font-face {
	font-family: "Orbi Sans W01 Regular";
	src: url("./assets/fonts/7f99c8219a2322281b6f64a6248367af.eot");
	src: url("./assets/fonts/7f99c8219a2322281b6f64a6248367af.eot?#iefix")
			format("embedded-opentype"),
		url("./assets/fonts/7f99c8219a2322281b6f64a6248367af.woff")
			format("woff"),
		url("./assets/fonts/7f99c8219a2322281b6f64a6248367af.woff2")
			format("woff2"),
		url("./assets/fonts/7f99c8219a2322281b6f64a6248367af.ttf")
			format("truetype"),
		url("./assets/fonts/7f99c8219a2322281b6f64a6248367af.svg") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@layer base {
	html {
		font-family: "Orbi Sans W01 Regular";
		letter-spacing: 1px;
		@apply text-secondary;
	}
}

.container {
	@apply w-11/12 lg:w-9/12 mx-auto py-2 max-w-[1200px];
}

.copywrite {
	@apply py-2 border-t border-gray-100 text-center text-gray-400 mt-5;
}

.footer-bg {
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: 100% 70%;
	background-image: url("./assets/images/footer-bg.svg");
}
.logo-hide{
	@apply opacity-0;
}
.logo-show{
	@apply opacity-100;
}
.menu-lg-bg{
	@apply bg-white rounded-full shadow-md;
}
.btn-loading{
	@apply border-2 border-accent bg-white rounded-lg p-2 text-gray-500;
}
.btn {
	@apply bg-primary p-2 text-white rounded-lg duration-500 hover:bg-accent;
}
.btn > svg {
	@apply size-6 inline mr-1;
}
header {
	@apply py-2;
}
footer {
	@apply pt-14;
}

.alert-error{
	@apply bg-accent text-white px-3 text-center text-sm py-3 rounded-full shadow-md;
}

.adroit-list {
	@apply  list-inside mt-3 list-image-[url("./assets/images/checkmark.svg")]
}

section {
	@apply py-14;

	& h2 {
		@apply font-bold text-3xl mb-5 text-primary;
	}
	& h3{
		@apply font-bold text-2xl mb-4 text-secondary;
	}
	& p {
		@apply text-lg;
	}
}

.hero {
	@apply h-5/6;
}

.light-bg {
	@apply bg-primary bg-opacity-[0.04];
}

.floating-line-bg {
	@apply bg-cover;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url("./assets/images/floating-lines.svg");
}

.polygon-bg {
	@apply bg-cover lg:bg-contain;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url("./assets/images/polygon-scatter.svg");
}

.polygon-big-bg {
	@apply md:bg-contain bg-cover;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url("./assets/images/polygon-scatter-big.svg");
}

.expertise {
	@apply mb-10;

	& > h3 {
		@apply text-2xl mb-5 inline-block relative;
	}
	&:hover > h3:after {
		content: "";
		@apply w-full border-b border-primary absolute bottom-0 left-0 duration-500;
	}
	& > div {
		@apply flex flex-wrap justify-center items-center gap-10;
	}
	& > div > img {
		@apply w-[150px];
	}
}

.card {
	@apply relative p-3 border border-secondary hover:border-primary rounded-lg hover:shadow-md duration-500;

	& > * {
		@apply mb-2;
	}

	& > div:first-child {
		@apply w-3/4 mx-auto;
	}
	& > h3 {
		@apply font-bold duration-500;
	}
	& > p {
		@apply text-sm text-gray-500 text-pretty mb-5;
	}
	& > a {
		@apply absolute bottom-0 font-semibold right-5;
	}
}

/* 
================================================
    Footer Menu Styling.
================================================
*/
.footer-menu {
	@apply mb-5;
}
.footer-menu > h4 {
	@apply font-semibold text-lg mb-2 text-primary;
}

.footer-menu div {
	@apply mb-2;
}
.footer-menu a {
	@apply hover:text-primary duration-500;
}
.footer-menu a:before {
	content: "-";
	@apply font-bold mr-3 text-primary;
}
